import { NextSeo } from 'next-seo';

const NotFound = props => {
  return (
    <>
      <NextSeo title="Page not found" />
      <div className="w-screen h-screen flex justify-center items-center divide-x-2 divide-gray-400 space-x-4">
        <h1 className="text-5xl text-gray-900">404</h1>
        <div className="pl-4 text-lg text-gray-900">
          That page appears to be missing
        </div>
      </div>
    </>
  );
};

export default NotFound;
